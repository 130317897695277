<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2>{{ $t('buyerInfo.editAddress') }}</h2>
            <AddressForm is-edit-mode :address="address" :address-errors="addressErrors">
                <template #footer="{ address }">
                    <div class="btn__wrapper">
                        <CRButton class="btn" @click="update(address)">
                            {{ $t('buttons.update') }}
                        </CRButton>
                        <CRButton class="btn" @click="destroy(address)" color="danger">
                            {{ $t('buttons.delete') }}
                        </CRButton>
                    </div>
                </template>
            </AddressForm>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import AddressForm from '@/components/Forms/AddressForm.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { CRUDTypeEnum } from '@/utils/enums';
    import { TYPE } from 'vue-toastification';
    import { mapActions } from 'vuex';
    import { cloneDeep } from 'lodash';
    import { useVuelidate } from '@vuelidate/core';

    export default {
        name: 'EditAddressModal',
        components: { BasicModal, AddressForm, CRButton },
        props: {
            address: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['close', 'updated', 'destroyed'],
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                addressErrors: {},
            };
        },
        methods: {
            ...mapActions('auth', ['destroyAddress', 'updateAddress']),

            async update(address) {
                try {
                    const result = await this.v$.$validate();
                    if (!result) return;

                    let addressCopy = cloneDeep(address);

                    addressCopy.cityId = addressCopy.city.id;
                    delete addressCopy.city;

                    await this.updateAddress(addressCopy);

                    this.$emit('updated');
                    this.$emit('close');

                    this.$filters.toast(addressCopy.name + this.$t(`otherSettings.${CRUDTypeEnum.UPDATED}`), {
                        type: TYPE.SUCCESS,
                    });
                } catch (error) {
                    this.addressErrors = error.errors;
                }
            },

            async destroy(address) {
                try {
                    await this.destroyAddress(address.id);

                    this.$filters.toast(address.name + this.$t(`otherSettings.${CRUDTypeEnum.DELETED}`), {
                        type: TYPE.SUCCESS,
                    });

                    this.$emit('destroyed');
                    this.$emit('close');
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center-justify-center;

        width: 100%;

        padding: 28px 10px;

        h2 {
            margin-bottom: 32px;
        }

        .btn__wrapper {
            @include row-align-center;
            flex-wrap: wrap;
            margin-top: 30px;

            .btn {
                margin-right: 8px;
            }

            .btn:last-child {
                margin-right: 0;
            }
        }
    }

    @include media($lg) {
        .container {
            padding: 30px;

            h2 {
                font-size: $font-32;
            }
        }
    }
</style>
