import { translation } from '@/helpers/TranslationHelper';

export const renderAddressInputsData = (isEditMode) => {
    return [
        isEditMode && {
            id: 1,
            class: 'input street',
            value: 'name',
            type: 'text',
            isRequired: true,
            label: translation('common.name'),
            placeholder: translation('placeholder.enterName'),
            component: 'CRInput',
            autocomplete: ''
        },
        {
            id: 2,
            class: 'input street',
            value: 'street',
            type: 'text',
            isRequired: true,
            label: translation('common.street'),
            placeholder: translation('placeholder.enterStreet'),
            component: 'CRInput',
            autocomplete: 'address-line1'
        },
        {
            id: 3,
            class: 'input',
            value: 'houseNumber',
            type: 'text',
            isRequired: true,
            label: translation('common.houseNumber'),
            placeholder: translation('common.houseNumber'),
            component: 'CRInput',
        },
        {
            id: 4,
            class: 'input',
            value: 'apartmentNumber',
            type: 'number',
            isRequired: false,
            label: translation('common.apartmentNumber'),
            placeholder: translation('placeholder.enterApartmentNumber'),
            component: 'CRInput',
        },
        {
            id: 5,
            class: 'input',
            value: 'floor',
            type: 'number',
            isRequired: false,
            label: translation('common.floor'),
            placeholder: translation('placeholder.enterFloor'),
            component: 'CRInput',
        },
        {
            id: 6,
            class: 'input',
            value: 'postalCode',
            type: 'number',
            isRequired: false,
            label: translation('common.postalCode'),
            placeholder: translation('placeholder.enterPostalCode'),
            component: 'CRInput',
            autocomplete: 'postal-code'
        },
        {
            id: 7,
            class: 'textarea',
            value: 'extraInformation',
            type: 'text',
            isRequired: false,
            label: translation('common.extraInformation'),
            placeholder: translation('placeholder.enterExtraInformation'),
            maxLength: 500,
            component: 'CRTextarea',
        },
    ];
};
